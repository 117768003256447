import styled from 'styled-components';

export const ListWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 0.75rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 1.25rem;
  column-gap: 1.25rem;
  @media screen and (min-width: 641px) and (max-width: 990px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media screen and (min-width: 991px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media screen and (max-width: 640px) {
    row-gap: 1px;
    column-gap: 1px;
  }
`;
export const TileWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.uiGrey02};
  background-color: ${(props) => props.theme.whiteColor};
  position: relative;

  &:after {
    content: url(/assets/chevron-right.svg);
    position: absolute;

    top: calc(50% - 10px);
    right: 0.5rem;
    @media screen and (min-width: 991px) {
      right: 1.25rem;
    }
  }
  &:hover {
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
    cursor: pointer;
  }
`;
