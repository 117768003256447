import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import ExternalImage from '../../common/Images/ExternalImage';
// Components
import Tooltip from '../../common/Tooltip/Tooltip';
import ErrorMessage from '../../common/Errors/ErrorMessage';

// Styles
import { GKActivation } from '../FormStyles';

// Helpers
import { displayFieldErrorMessage } from '../../../helpers/formHelpers';
import Checkboxes from '../fields/Checkboxes';

const GKActivationCheckboxForm = ({
  ghostKitTitle,
  ghostKitTitleTooltip,
  yesLabel,
  noLabel,
  ghostKitImage,
  errorsList,
  gaFlow = '',
}) => {
  const { control, formState } = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const error = formState?.errors?.activateGK;
  const isError = (error && formState.touchedFields?.activateGK) || (error && formState.isSubmitted);

  return (
    <GKActivation className="relative">
      <div className="gk-tooltip">
        <Tooltip dataForId="activationCodeSelection" title={ghostKitTitle} message={ghostKitTitleTooltip} isHTML iconSmall />
      </div>
      <div className="mt-4 flex flex-start">
        <Checkboxes
          options={['yes', 'no']}
          labels={[yesLabel, noLabel]}
          name="activateGK"
          control={control}
          isToggle
          errorMessage={displayFieldErrorMessage(errorsList, 'Customer.GKYesNo.Required')}
          gaFlow={gaFlow}
        />
      </div>
      {isError && <ErrorMessage error={error} />}
      {ghostKitImage?.url && (
        <div className="absolute top-0 right-0 w-[4.575rem] h-full">
          <ExternalImage url={ghostKitImage?.url} width={73} height={110} />
        </div>
      )}
    </GKActivation>
  );
};

export default GKActivationCheckboxForm;

GKActivationCheckboxForm.propTypes = {
  ghostKitTitle: PropTypes.string,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  ghostKitTitleTooltip: PropTypes.string,
  errorsList: PropTypes.array,
  ghostKitImage: PropTypes.object,
  gaFlow: PropTypes.string,
};
